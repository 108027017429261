// middleware/isAdmin.ts
// https://dev.to/rafaelmagalhaes/authentication-in-nuxt-3-375o
import { storeToRefs } from 'pinia'; // import storeToRefs helper hook from pinia
import { useAuthStore } from '~/store/auth'; // import the auth store we just created
import type { IEmailSettings } from '~/types'; // import the IUser type

export default defineNuxtRouteMiddleware(async (to) => {


  const { logOutUser } = useAuthStore();
  const { authenticated, role, userId, clientId } = storeToRefs(useAuthStore()); // make authenticated state reactive
  const _vh_user_token = useCookie('_vh_user_token'); // get token from cookies
  const _vh_logged_in_user_id = useCookie('_vh_logged_in_user_id'); // get token from cookies
  const _vh_logged_in_client_id = useCookie('_vh_logged_in_client_id'); // get token from cookies

  //! Check if token is valid
  if (_vh_user_token.value) {
    const checkUser = await useFetchAuth('/api/v1/back/user/verify',
      {
        method: 'POST',
        body: {
          token: _vh_user_token.value
        }
      });

    // Check user will return 'failed' if token is invalid or it will return the role
    if ((checkUser as { loggedInRole: string }).loggedInRole === 'admin') {
      authenticated.value = true;
      role.value = 'admin';
      userId.value = _vh_logged_in_user_id.value ?? '';
      clientId.value = _vh_logged_in_client_id.value ?? '';

      // if token exists and url is /login redirect to homepage
      if (to?.name === 'login') {
        return navigateTo('/');
      }
    }
    // Logout, destroy state and cookies
    else {
      logOutUser();
      useToast().error('Your session has expired. Please log in again.');
      // if token doesn't exist redirect to log in
      if (to?.name !== 'login') {
        abortNavigation();
        return navigateTo('/login');
      }
    }
  }
  //! Token does not exist
  else {
    logOutUser();
    useToast().error('Your session has expired. Please log in again.');
    abortNavigation();
    return navigateTo('/login');
  }
});